<template>
  <v-app>
    <dashboard-core-app-bar v-show="isDrawer" />

    <dashboard-core-drawer v-show="isDrawer" />
    <Snackbars />
    <dashboard-core-view />

    <!-- <dashboard-core-settings /> -->
  </v-app>
</template>

<script>
export default {
  name: "DashboardIndex",
  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    // DashboardCoreSettings: () => import('./components/core/Settings'),
    Snackbars: () => import("./Common/Snackbars"),
    DashboardCoreView: () => import("./components/core/View")
  },
  data: () => ({
    expandOnHover: false
  }),
  computed: {
    isDrawer() {
      return this.$route.meta.isDrawer;
    }
  }
};
</script>
